import React, { Component } from "react";
import { View, Text, TouchableOpacity, ScrollView, Image } from "react-native";
import moment from "moment";
import Font from "react-font";
import { APP_COLOURS } from "../APP_VARS";
import CellNumber from "./CellNumber";
import EmailInput from "./EmailInput";
import FullName from "./FullName";
import DateOfBirth from "./DateOfBirth";
import Policy from "./Policy";
import OptIn from "./OptIn";
import con_logo from "../assets/images/con_logo.png";

export default class UserDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			first_name: "",
			last_name: "",
			cell: "",
			email: "",
			policy: false,
		};
	}

	render() {
		let sh = this.props.screenHeight;
		let sw = this.props.screenWidth;
		return (
			<View
				style={{
					width: sw,
					height: sh,
					backgroundColor: "rgba(52, 52, 52, 0.6)",
					position: "absolute",
					zIndex: 110,
					top: 0,
					width: "100%",
					height: "100%",
				}}>
				<ScrollView
					contentContainerStyle={{
						padding: 20,
					}}>
					<View
						style={{
							width: "100%",
							// backgroundColor: "#FFF",
							backgroundColor: "#F7F7F7",
							borderRadius: 20,
							padding: 20,
							maxWidth: 500,
							marginRight: "auto",
							marginLeft: "auto",
						}}>
						<View
							style={{
								position: "absolute",
								zIndex: 99,
								top: 10,
								left: 10,
							}}>
							<TouchableOpacity
								onPress={() => {
									window.location.href = "./";
								}}>
								<Image
									source={require("./../assets/images/close.png")}
									style={{
										width: 30,
										height: 30,
										resizeMode: "contain",
									}}
								/>
							</TouchableOpacity>
						</View>

						<View
							style={{
								width: "100%",
								justifyContent: "center",
								alignItems: "center",
								marginBottom: 30,
							}}>
							<Image
								source={con_logo}
								style={{
									width: "60%",
									height: 50,
									resizeMode: "contain",
									margin: 20,
								}}
							/>
						</View>

						<View
							style={{
								marginBottom: 5,
								alignItems: "center",
								justifyContent: "center",
								width: "100%",
								marginBottom: 30,
							}}>
							<Text
								style={{
									color: "#000",
									fontSize: 25,
									marginBottom: 15,
								}}>
								<Font weight={700} family='Montserrat'>
									Your almost done
								</Font>
							</Text>
							<Text
								style={{
									color: "#000",
									fontSize: 15,
									opacity: 0.8,
								}}>
								<Font weight={500} family='Montserrat'>
									Please fill in your details to recieve your results.
								</Font>
							</Text>
						</View>

						<View>
							<div
								style={{
									flex: 1,
									alignItems: "center",
									justifyContent: "center",
									marginBottom: 25,
									padding: 10,
								}}>
								{/* <View
									style={
										{
											// justifyContent: "center",
										}
									}>
									<View
										style={{
											flexDirection: "row",
											marginTop: 5,
											// padding: 10,
											alignItems: "center",
											justifyContent: "center",
											// paddingLeft: 16,
											width: "100%",
										}}>
										<Text
											style={{
												color: "#000",
												fontSize: 12,
											}}>
											<Font weight={500} family='Montserrat'>
												Full Name
											</Font>
										</Text>
									</View>
								</View> */}

								<FullName
									first_name={(d) => this.setState({ first_name: d })}
									last_name={(d) => this.setState({ last_name: d })}
								/>
							</div>
						</View>
						<View>
							<div
								style={{
									flex: 1,
									alignItems: "center",
									justifyContent: "center",
									// padding: 10,
									// maxWidth: 380,
									marginBottom: 25,
								}}>
								{/* <View
									style={
										{
											// justifyContent: "center",
										}
									}>
									<View
										style={{
											flexDirection: "row",
											marginTop: 5,
											// padding: 10,
											alignItems: "center",
											justifyContent: "center",
											// paddingLeft: 16,
											width: "100%",
										}}>
										<Text
											style={{
												color: "#000",
												fontSize: 12,
											}}>
											<Font weight={500} family='Montserrat'>
												Gender
											</Font>
										</Text>
									</View>
								</View> */}
								<View
									style={{
										width: "100%",
										flexDirection: "row",
									}}>
									<TouchableOpacity
										style={{
											flex: 1,
											margin: 10,
										}}
										onPress={async () => {
											this.setState({
												gender: "male",
											});
										}}>
										<View
											style={{
												backgroundColor:
													this.state.gender === "male"
														? "#000"
														: "#FFF",
												borderWidth: 0.3,
												borderColor: "#000",
												height: "auto",
												padding: 12,
												borderRadius: 12,
												justifyContent: "center",
												alignItems: "center",
											}}>
											<Text
												style={{
													alignItems: "center",
													justifyContent: "center",
													color:
														this.state.gender === "male"
															? "#FFF"
															: "#000",
													fontSize: 15,
												}}>
												<Font
													weight={
														this.state.gender === "male"
															? 700
															: 500
													}
													family='Montserrat'>
													Male
												</Font>
											</Text>
										</View>
									</TouchableOpacity>
									<TouchableOpacity
										style={{
											flex: 1,
											margin: 10,
										}}
										onPress={async () => {
											this.setState({
												gender: "female",
											});
										}}>
										<View
											style={{
												backgroundColor:
													this.state.gender === "female"
														? "#000"
														: "#FFF",
												borderWidth: 0.3,
												borderColor: "#000",
												height: "auto",
												padding: 12,
												borderRadius: 12,
												justifyContent: "center",
												alignItems: "center",
											}}>
											<Text
												style={{
													alignItems: "center",
													justifyContent: "center",
													color:
														this.state.gender === "female"
															? "#FFF"
															: "#000",
													fontSize: 15,
												}}>
												<Font
													weight={
														this.state.gender === "female"
															? 700
															: 500
													}
													family='Montserrat'>
													Female
												</Font>
											</Text>
										</View>
									</TouchableOpacity>
								</View>
							</div>
						</View>
						<View
							style={{
								justifyContent: "center",
								// maxWidth: 280,
								marginBottom: 25,
								padding: 10,
							}}>
							{/* <View
								style={
									{
										// justifyContent: "center",
									}
								}>
								<View
									style={{
										flexDirection: "row",
										marginTop: 5,
										// padding: 10,
										alignItems: "center",
										justifyContent: "center",
										// paddingLeft: 16,
										width: "100%",
									}}>
									<Text
										style={{
											color: "#000",
											fontSize: 12,
										}}>
										<Font weight={500} family='Montserrat'>
											Email
										</Font>
									</Text>
								</View>
							</View> */}
							<EmailInput
								textAlign={"center"}
								setParentState={(d) =>
									this.setState({
										email: d,
									})
								}
							/>
						</View>

						<View
							style={{
								justifyContent: "center",
								// maxWidth: 280,
								marginBottom: 25,
								padding: 10,
							}}>
							{/* <View
								style={
									{
										// justifyContent: "center",
									}
								}>
								<View
									style={{
										flexDirection: "row",
										marginTop: 5,
										// padding: 10,
										alignItems: "center",
										justifyContent: "center",
										// paddingLeft: 16,
										width: "100%",
									}}>
									<Text
										style={{
											color: "#000",
											fontSize: 12,
										}}>
										<Font weight={500} family='Montserrat'>
											Cell number
										</Font>
									</Text>
								</View>
							</View> */}
							<CellNumber
								title={"Cell number"}
								textAlign={"center"}
								setParentState={(d) =>
									this.setState({
										cell: d,
									})
								}
							/>
						</View>

						<View>
							<div
								style={{
									flex: 1,
									alignItems: "center",
									justifyContent: "center",
									marginBottom: 25,
									padding: 10,
								}}>
								<View
									style={{
										flexDirection: "row",
										marginBottom: 5,
										alignItems: "center",
										justifyContent: "center",
										width: "100%",
									}}>
									<Text
										style={{
											color: "#000",
											fontSize: 12,
										}}>
										<Font weight={500} family='Montserrat'>
											Date of birth
										</Font>
									</Text>
								</View>

								<DateOfBirth
									setParentState={(d) =>
										this.setState({
											dob: d,
										})
									}
								/>
							</div>
						</View>

						<View>
							<div
								style={{
									flex: 1,
									alignItems: "center",
									justifyContent: "center",
									marginBottom: 50,
									padding: 10,
								}}>
								<OptIn
									screenWidth={this.props.screenWidth}
									screenHeight={this.props.screenHeight}
									setParentState={(d) =>
										this.setState({
											opt_in: d,
										})
									}
								/>
								<View
									style={{
										height: 10,
									}}
								/>
								<Policy
									screenWidth={this.props.screenWidth}
									screenHeight={this.props.screenHeight}
									setParentState={(d) =>
										this.setState({
											policy: d,
										})
									}
								/>
							</div>
						</View>

						{this.state.first_name !== "" &&
						this.state.last_name !== "" &&
						(this.state.email !== "" || this.state.cell !== "") &&
						this.state.policy ? (
							<View
								ref={(ref) => (this.myRef = ref)}
								onLayout={() =>
									this.myRef.scrollIntoView({
										behavior: "smooth",
										block: "start",
									})
								}>
								<TouchableOpacity
									style={{
										width: "100%",
										marginTop: 15,
										marginBottom: 40,
									}}
									onPress={async () => {
										alert(
											"This is a test application, Please contact tech support for more information",
										);
									}}>
									<View
										style={{
											backgroundColor: APP_COLOURS.RED,
											width: "100%",
											height: 45,
											borderRadius: 25,
											justifyContent: "center",
											alignItems: "center",
										}}>
										<Text
											style={{
												alignItems: "center",
												justifyContent: "center",
												color: "#FFF",
												fontSize: 17,
											}}>
											<Font weight={700} family='Montserrat'>
												See prescription
											</Font>
										</Text>
									</View>
								</TouchableOpacity>
							</View>
						) : (
							<View />
						)}

						<View
							style={{
								width: "100%",
								height: 50,
								// marginBottom: 12,
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "row",
								// borderTopWidth: 0.5,
								opacity: 0.8,
								position: "absolute",
								zIndex: 999,
								bottom: 0,
							}}>
							<Text
								style={{
									fontSize: 11,
									color: "#141D31",
								}}>
								© {moment().format("YYYY")} TRAQ Powered by{" "}
							</Text>

							<Text
								style={{
									fontSize: 12,
									paddingTop: 1,
									color: "#141D31",
								}}>
								<a
									style={{
										textDecoration: "none",
										marginTop: 20,
										color: "#141D31",
									}}
									target='_blank'
									rel='noreferrer'
									href='https://www.tetrice.co.za'>
									<Font
										weight={800}
										family='Poppins'
										color={"#141D31"}>
										tetrice
									</Font>
								</a>
							</Text>
						</View>
					</View>
				</ScrollView>
			</View>
		);
	}
}
