let e = `https://us-central1-traq-e3acf.cloudfunctions.net/app`;

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
	e = `http://localhost:5001/traq-e3acf/us-central1/app`;
}

export const api_deviceRegister = e + "/survey/login";
export const api_submitSurvey = e + "/survey/submitSurvey";

export const api_heartBeat = e + "/survey/heartBeat";

// export const api_getStaffMembers = e + "/admin/getStaffMembers";
// export const api_addStaffMember = e + "/admin/addStaffMember";
// export const api_deleteStaffMember = e + "/admin/deleteStaff";

// export const api_addMembership = e + "/admin/addMembership";
// export const api_deleteMembership = e + "/admin/deleteMembership";
// export const api_getAllMemberships = e + "/admin/getAllMemberships";

// export const api_memberSignup = e + "/admin/memberSignup";
// export const api_verifyotp = e + "/auth/verifyotp";
