import React, { Component } from "react";
import ReactDOM from "react-dom";
import { View, Text, ScrollView, Image, TouchableOpacity } from "react-native";
import moment from "moment";
import Font from "react-font";
import { APP_COLOURS } from "../APP_VARS";
import Fade from "react-reveal/Fade";
import "./question.css";
import con_logo from "../assets/images/con_logo.png";
import QRCode from "react-qr-code";

import p75724428010 from "./ProductImages/Straight Moisture/75724428010_1 (5).png";
import p75724428003 from "./ProductImages/Straight Moisture/75724428003.png";
import p75724428034 from "./ProductImages/Straight Moisture/75724428034_1.png";
import p75724252004 from "./ProductImages/Straight Moisture/75724252004_1 (1).png";

import p75724428126 from "./ProductImages/Straight Strength/75724428126_1.png";
import p75724004559 from "./ProductImages/Straight Shine/75724004559.png";

import p75724251991 from "./ProductImages/Coil moisture/75724251991_1 (2).jpg";
import p75724245662 from "./ProductImages/Coil moisture/75724245662_1.png";

import p75724251984 from "./ProductImages/Coil strength/75724251984_1 (2).png";
import p75724252011 from "./ProductImages/Coil strength/75724252011_1.png";
import p757242456621 from "./ProductImages/Coil strength/75724001299_1 .png";
import p757242456622 from "./ProductImages/Coil strength/75724001299_1 (2).png";

import p757242519841 from "./ProductImages/Coil Shine/75724251984_1 .png";
import p75724244429 from "./ProductImages/Coil Shine/75724244429_1 (2).png";

import pp75724251991 from "./ProductImages/Curl Moisture/75724251991_1 .png";
import pp75724252028 from "./ProductImages/Curl Moisture/75724252028_1 .png";

import pp75724252004 from "./ProductImages/Curl strength/75724252004_1.png";
import pp75724071285 from "./ProductImages/Curl strength/75724071285.png";

import pp75724244382 from "./ProductImages/Curl Shine/75724244382.png";
import pp75724244429 from "./ProductImages/Curl Shine/75724244429_1 .png";

import pp75724428003 from "./ProductImages/Wavy Moisture/75724428003_1.png";
import pp75724428010 from "./ProductImages/Wavy Moisture/75724428010_1 (3).png";

import pp75724004542 from "./ProductImages/Wavy strength/75724004542_1.png";
import pp75724428027 from "./ProductImages/Wavy strength/75724428027_1.png";

import ppp75724005150 from "./ProductImages/Wavy Shine/75724005150_1.png";
import ppp75724002333 from "./ProductImages/Wavy Shine/75724002333_1.png";
import ppp75724428072 from "./ProductImages/Wavy Shine/75724428072_1.png";

import pp75724005129 from "./ProductImages/Protective Moisture/75724005129_1 (2).png";
import pp75724005112 from "./ProductImages/Protective Moisture/75724005112_1.png";

import pp75724005082 from "./ProductImages/Protective  strength/75724005082_1.png";

import pp75724244405 from "./ProductImages/Protective Shine/75724244405_1.png";

import img2 from "./ProductImages/Cream Of Nature0854 copy.jpg";
import img3 from "./ProductImages/Cream Of Nature0993 copy.jpg";
import img1 from "./ProductImages/Cream Of Nature0788 copy.jpg";

export default class ResultsPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			stickyQr: false,
			prescription: { items: [] },
			colors: {
				orange: "straight",
				green: "wavy",
				curl: "yellow",
				blue: "coil",
			},
			scenarios: [
				{
					color: "orange",
					field2: "Moisture",
					items: [
						{
							num: 1,
							title: "Pure honey moisturising dry defense shampoo",
							img: p75724428003,
						},
						{
							num: 2,
							title: "Pure honey moisturizing dry defense Conditioner",
							img: p75724428010,
						},
						{
							num: 3,
							title: "Pure honey Knot Away  Leave -In Detangler ",
							img: p75724428034,
						},
						{
							num: 4,
							title: "Hydrating curl detangler leave-in conditioner",
							img: p75724252004,
						},
					],
				},
				{
					color: "orange",
					field2: "Strengthening",
					items: [
						{
							num: 1,
							title: "Pure honey moisturising dry defense shampoo",
							img: p75724428003,
						},
						{
							num: 2,
							title: "Pure honey moisturizing dry defense Conditioner",
							img: p75724428010,
						},
						{
							num: 3,
							title: "Pure honey break up breakage leave in conditioner",
							img: p75724428126,
						},
					],
				},
				{
					color: "orange",
					field2: "Shine",
					items: [
						{
							num: 1,
							title: "Pure honey moisturising dry defense shampoo",
							img: p75724428003,
						},
						{
							num: 2,
							title: "Pure honey moisturizing dry defense Conditioner",
							img: p75724428010,
						},
						{
							num: 3,
							title: "Pure honey intense hydration treatment hair mask (Yogurt)",
							img: p75724004559,
						},
					],
				},
				{
					color: "blue",
					field2: "Moisture",
					items: [
						{
							num: 1,
							title: "Argan oil sulfate free moisture & shine shampoo",
							img: p75724251991,
						},
						{
							num: 2,
							title: "Argan oil intensive conditioning treatment ",
							img: p75724245662,
						},
					],
				},
				{
					color: "blue",
					field2: "Strengthening",
					items: [
						{
							num: 1,
							title: "Argan oil Treatment",
							img: p75724251984,
						},
						{
							num: 2,
							title: "Argan oil creamy oil moisturising hair lotion",
							img: p75724252011,
						},
						{
							num: 3,
							title: "Aloe & black castor oil anti breakage overnight treatment ",
							img: p757242456621,
						},
						{
							num: 4,
							title: "Aloe & black castor oil  reconstructing Anti-breakage Leave in",
							img: p757242456622,
						},
					],
				},
				{
					color: "blue",
					field2: "Shine",
					items: [
						{
							num: 1,
							title: "100% pure argan oil  treatment ",
							img: p757242519841,
						},
						{
							num: 2,
							title: "Argan oil Heat protector smooth & shine polisher",
							img: p75724244429,
						},
					],
				},
				{
					color: "yellow",
					field2: "Moisture",
					items: [
						{
							num: 1,
							title: "Argan oil moisture & shine shampoo",
							img: pp75724251991,
						},
						{
							num: 2,
							title: "Argan oil intensive conditioning treatment ",
							img: pp75724252028,
						},
					],
				},
				{
					color: "yellow",
					field2: "Strengthening",
					items: [
						{
							num: 1,
							title: "Argan oil strength & shine leave in conditioner",
							img: pp75724252004,
						},
						{
							num: 2,
							title: "Strengthen and shine leave in conditioner",
							img: pp75724071285,
						},
					],
				},
				{
					color: "yellow",
					field2: "Shine",
					items: [
						{
							num: 1,
							title: "Argan oil style & shine foaming mousse",
							img: pp75724244382,
						},
						{
							num: 2,
							title: "Argan oil Heat protector smooth & shine polisher",
							img: pp75724244429,
						},
					],
				},
				{
					color: "green",
					field2: "Moisture",
					items: [
						{
							num: 1,
							title: "Pure honey moisturising dry defense shampoo",
							img: pp75724428003,
						},
						{
							num: 2,
							title: "Pure honey moisturizing dry defense Conditioner",
							img: pp75724428010,
						},
					],
				},
				{
					color: "green",
					field2: "Strengthening",
					items: [
						{
							num: 1,
							title: "Pure honey Moisture replenish & Strength Hair Mask ",
							img: pp75724428027,
						},
						{
							num: 2,
							title: "Pure Honey smoothing & Frizz control hair mask (avocado)",
							img: pp75724004542,
						},
					],
				},
				{
					color: "green",
					field2: "Shine",
					items: [
						{
							num: 1,
							title: "Pure honey moisture & twist  curling mousse",
							img: ppp75724005150,
						},
						{
							num: 2,
							title: "Pure honey moisturising twisiting cream",
							img: ppp75724002333,
						},
						{
							num: 2,
							title: "Pure honey  twist & hold Defining custard",
							img: ppp75724428072,
						},
					],
				},

				{
					color: "purple",
					field2: "Moisture",
					items: [
						{
							num: 1,
							title: "Pure honey Scalp refresh restorative daily scalp cream",
							img: pp75724005129,
						},
						{
							num: 2,
							title: "Pure Honey scalp fresh invigorating scalp oil",
							img: pp75724005112,
						},
					],
				},
				{
					color: "purple",
					field2: "Strengthening",
					items: [
						{
							num: 1,
							title: "Pure honey Scalp refresh restorative daily scalp cream",
							img: pp75724005129,
						},
						{
							num: 2,
							title: "Pure honey scalp refresh invigoarating leave in",
							img: pp75724005082,
						},
					],
				},
				{
					color: "purple",
					field2: "Shine",
					items: [
						{
							num: 1,
							title: "Argan oil Anti-Humidity Gloss & Shine mist",
							img: pp75724244405,
						},
					],
				},
			],
			color: "",
			field2: "",
		};
	}

	render() {
		let sh = this.props.screenHeight;
		let sw = this.props.screenWidth;

		let ua = this.props.userAnswers;

		let color = "";
		let field2 = "";

		for (const d of ua) {
			if (d.question_number === 1) {
				if (d.mark === "protective" && color == "") {
					color = "purple";
				}
			}
			if (d.question_number === 2) {
				if (d.mark === "TypeA" && color == "") {
					color = "orange";
				}
				if (d.mark === "TypeB" && color == "") {
					color = "green";
				}
				if (d.mark === "TypeC" && color == "") {
					color = "yellow";
				}
				if (d.mark === "TypeD" && color == "") {
					color = "blue";
				}
			}
			if (d.question_number === 3) {
				if (d.mark === "Moisture") {
					field2 = "Moisture";
				}
				if (d.mark === "Strengthening") {
					field2 = "Strengthening";
				}
				if (d.mark === "Shine") {
					field2 = "Shine";
				}
			}
		}

		let sc = this.state.scenarios
			.filter((d) => d.color === color)
			.filter((d) => d.field2 === field2);

		const prescription = sc[0];

		console.log(color, field2, prescription);

		// return <View />;
		return (
			<View
				style={{
					width: sw,
					height: sh,
					position: "absolute",
					zIndex: 110,
					top: 0,

					// backgroundColor: "#FFF",
					backgroundColor: "rgba(255, 255, 255, 0.2)",
				}}>
				{/* <Fade>
					<div
						// className='blur'
						style={{
							flex: 1,
							justifyContent: "center",
							alignItems: "center",
							width: sw,
							height: sh,
						}}>
						<View
							style={{
								position: "absolute",
								top: 0,
								left: 0,
								zIndex: 99,
							}}>
							<Image
								source={require("./../assets/images/leftTop.png")}
								style={{
									width: 140,
									height: 140,
									resizeMode: "contain",
								}}
							/>
						</View>
						<View
							style={{
								position: "absolute",
								bottom: 0,
								left: 0,
								zIndex: 99,
							}}>
							<Image
								source={require("./../assets/images/pathBottomLeft.png")}
								style={{
									width: 200 * (849 / 708),
									height: 200,
									resizeMode: "contain",
								}}
							/>
						</View>
						<View
							style={{
								position: "absolute",
								top: 0,
								right: 0,
								zIndex: 99,
							}}>
							<Image
								source={require("./../assets/images/rightTop.png")}
								style={{
									width: 150 * (756.13 / 395.21),
									height: 150,
									resizeMode: "contain",
								}}
							/>
						</View>
						<View
							style={{
								position: "absolute",
								bottom: 0,
								right: 0,
								zIndex: 99,
							}}>
							<Image
								source={require("./../assets/images/rightBottom.png")}
								style={{
									width: 150 * (461 / 450),
									height: 150,
									resizeMode: "contain",
								}}
							/>
						</View>
					</div>
				</Fade> */}
				<ScrollView
					ref={(ref) => {
						this.scrollRef = ref;
					}}
					onScroll={(e) => {
						let yOff = e.nativeEvent.contentOffset.y;

						this.setState({
							stickyQr: yOff > 10,
						});
					}}
					scrollEventThrottle={16}
					contentContainerStyle={{}}>
					<View
						style={{
							width: sw,
							// height: sh,
							// position: "absolute",
							// zIndex: 999,
							// top: 0,
							// left: 0,
							minHeight: sh,
							// backgroundColor: "red",
							backgroundColor: "rgba(255, 255, 255, 0.2)",
						}}>
						<Fade collapse when={this.state.show}>
							<View
								style={{
									// top: 0,
									// left: 0,
									// position: "absolute",
									// zIndex: 99,
									// width: sw,
									// height: sh,
									justifyContent: "center",
									alignItems: "center",
									// padding: 40,
									paddingBottom: 80,
									//
								}}>
								<View
									style={{
										padding: 10,
										borderRadius: 15,
										backgroundColor: "#FFF",

										width: sw,
										justifyContent: "center",
										alignItems: "center",
										height: 150,
									}}>
									<View
										style={{
											position: "absolute",
											left: 4,
											top: 4,
											padding: 4,
											// flexDirection: "row",
											// marginRight: 15,
										}}>
										<View
											style={{
												// height: 125,
												// width: 125,
												display: this.state.stickyQr
													? "none"
													: "flex",
												padding: 5,
												justifyContent: "center",
												alignItems: "center",
												backgroundColor: "#FFF",
												borderRadius: 8,
												shadowColor: "#000",
												shadowOffset: {
													width: 0,
													height: 2,
												},
												shadowOpacity: 0.25,
												shadowRadius: 7.84,
												elevation: 12,
												zIndex: 999,
											}}>
											<Text
												style={{
													fontWeight: "700",
													width: 100,
													fontSize: 10,
													textAlign: "center",
													marginBottom: 4,
												}}>
												Save prescription to your phone
											</Text>
											<QRCode
												value={"https://traq.app/"}
												bgColor={"#FFF"}
												fgColor={"#000"}
												size={80}
											/>
											<Image
												source={require("./../assets/images/SCAN ME.png")}
												style={{
													width: 100,
													height: 9,
													marginTop: 5,
													resizeMode: "contain",
												}}
											/>
										</View>
									</View>
									<Image
										source={con_logo}
										style={{
											width: sw * 0.2,
											height: sh * 0.05,
											resizeMode: "contain",
											// position: "absolute",
											// zIndex: 999,
											// top: 20,
										}}
									/>
									<p
										style={{
											color: "#000",
											fontWeight: "900",
											marginTop: 15,
										}}>
										Your prescription
									</p>
								</View>
								<View
									style={{
										flexDirection: "row",
										justifyContent: "space-evenly",
										justifyContent: "center",
										alignItems: "center",
										marginTop: 20,
									}}>
									{/* [
										{
											num: 1,
											option: "Prouduct one",
											mark: "",
											img: img1,
											imgH: sw / 4,
										},
										{
											num: 2,
											option: "Product two",
											mark: "",
											img: img2,
											imgH: sw / 4,
										},
										{
											num: 3,
											option: "Product three",
											mark: "",
											img: img3,
											imgH: sw / 4,
										},
									] */}

									{typeof prescription.items !== "undefined"
										? prescription.items.map((d, i) => (
												<View
													style={{
														flex: 1,
														alignItems: "center",
														width:
															sw /
															(prescription.items.length + 1),
														// padding: 10,
														borderRadius: 15,
														backgroundColor: "#FFF",
														margin: 10,
														shadowColor: "#797979",
														shadowOffset: {
															width: 0,
															height: 2,
														},
														shadowOpacity: 0.25,
														shadowRadius: 7.84,
														elevation: 12,
														padding: 20,
													}}>
													<Image
														source={d.img}
														style={{
															width:
																sw /
																(prescription.items.length + 1),
															height:
																sw /
																(prescription.items.length + 1),
															resizeMode: "contain",
															// borderRadius: 4,
															borderRadius: 15,
														}}
													/>
													<View
														style={{
															justifyContent: "center",
															alignItems: "center",
															marginBottom: 20,
														}}>
														<Text
															style={{
																color: "#000",
																fontWeight: "500",
																textAlign: "center",
																fontFamily: "Avenir",
																fontSize: 25,
																marginTop: 20,
															}}>
															{d.title}
														</Text>
													</View>
													{/* <View
														style={{
															width: 15,
															height: 15,
															borderRadius: 4,
															backgroundColor: color,
															display: "none",
														}}
													/> */}
													{/* <View
														style={{
															justifyContent: "center",
															alignItems: "center",
														}}>
														<p
															style={{
																color: "#000",
																fontWeight: "500",
																textAlign: "center",
															}}>
															{this.props.userAnswers[0].answer}
														</p>
													</View>
													<View
														style={{
															justifyContent: "center",
															alignItems: "center",
														}}>
														<p
															style={{
																color: "#000",
																fontWeight: "500",
																textAlign: "center",
															}}>
															{field2}
														</p>
													</View> */}
												</View>
										  ))
										: null}
								</View>
							</View>
						</Fade>
					</View>
					<View
						style={{
							width: "100%",
							height: 50,
							// marginBottom: 12,
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "row",
							// borderTopWidth: 0.5,
							opacity: 0.8,
							position: "absolute",
							zIndex: 999,
							bottom: 0,
						}}>
						<Text
							style={{
								fontSize: 11,
								color: "#141D31",
							}}>
							© {moment().format("YYYY")} TRAQ Powered by{" "}
						</Text>

						<Text
							style={{
								fontSize: 12,
								paddingTop: 1,
								color: "#141D31",
							}}>
							<a
								style={{
									textDecoration: "none",
									marginTop: 20,
									color: "#141D31",
								}}
								target='_blank'
								rel='noreferrer'
								href='https://www.tetrice.co.za'>
								<Font weight={800} family='Poppins' color={"#141D31"}>
									tetrice
								</Font>
							</a>
						</Text>
					</View>
				</ScrollView>

				<View
					style={{
						position: "absolute",
						left: 0,
						top: 0,
						padding: 4,
						display: this.state.stickyQr ? "flex" : "none",
					}}>
					<View
						style={{
							// height: 125,
							// width: 125,
							padding: 5,
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "#FFF",
							borderRadius: 8,
							shadowColor: "#000",
							shadowOffset: {
								width: 0,
								height: 2,
							},
							shadowOpacity: 0.25,
							shadowRadius: 7.84,
							elevation: 12,
							zIndex: 999,
						}}>
						<Text
							style={{
								fontWeight: "700",
								width: 100,
								fontSize: 10,
								textAlign: "center",
								marginBottom: 4,
							}}>
							Save prescription to your phone
						</Text>
						<QRCode
							value={"https://traq.app/"}
							bgColor={"#FFF"}
							fgColor={"#000"}
							size={80}
						/>
						<Image
							source={require("./../assets/images/SCAN ME.png")}
							style={{
								width: 100,
								height: 9,
								marginTop: 5,
								resizeMode: "contain",
							}}
						/>
					</View>
				</View>
			</View>
		);
	}
}
