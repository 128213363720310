import React, { Component } from "react";
import { View, Text, Dimensions, Image, TouchableOpacity } from "react-native";
import { APP_COLOURS } from "../APP_VARS";
import Fade from "react-reveal/Fade";
import "./blur.css";

export default class Page2 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
		};
	}

	async componentDidMount() {
		setTimeout(() => {
			this.setState({
				show: true,
			});
		}, 500);
	}

	render() {
		let ar = 937 / 649;

		let sh = this.props.screenHeight;
		let sw = this.props.screenWidth;
		return (
			<View
				style={{
					flex: 1,
					justifyContent: "center",
					alignItems: "center",
					width: sw,
					height: sh,
				}}>
				<Fade collapse when={this.state.show}>
					<TouchableOpacity onPress={() => this.props.nextPage()}>
						<View
							style={{
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: "red",
								width: 280,
								height: 40,
							}}>
							<Text>Page 2</Text>
						</View>
					</TouchableOpacity>
				</Fade>
			</View>
		);
	}
}
