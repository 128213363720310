import React, { Component } from "react";
import { View, Text, Dimensions, Image, TouchableOpacity } from "react-native";
import { APP_COLOURS } from "../APP_VARS";
import Fade from "react-reveal/Fade";
import "./question.css";
// import img1 from "../assets/images/img1.png";
// import img2 from "../assets/images/img2.png";
// import img3 from "../assets/images/img3.png";

export default class Page1 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
		};
	}

	async componentDidMount() {
		setTimeout(() => {
			this.setState({
				show: true,
			});
		}, 300);
	}

	nextPage = (d) => {
		this.setState({
			show: false,
		});

		setTimeout(() => {
			this.props.nextPage(d);
		}, 300);
	};

	options = (sh, sw) => {
		let colors = ["#25B4C1", "#E74A25", "#F8AE1D", "#DFAFA5"];
		return (
			<Fade collapse when={this.state.show}>
				<View
					style={{
						top: 0,
						left: 0,
						position: "absolute",
						zIndex: 99,
						width: sw,
						height: sh,
						justifyContent: "center",
						alignItems: "center",
						padding: 40,
						paddingBottom: 80,
					}}>
					<p
						style={{
							color: "#000",
							fontWeight: "500",
							padding: 20,
							marginBottom: 15,
						}}>
						{this.props.question}
					</p>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-evenly",
							justifyContent: "center",
							alignItems: "center",
						}}>
						{this.props.options.map((d, i) => (
							<TouchableOpacity
								key={i}
								style={{
									flex: 1,
									alignItems: "center",
									minWidth: sw / (this.props.options.length + 1),
									backgroundColor: colors[i],
									// backgroundColor: "#FFF",
									// borderWidth: 1,
									// borderColor: "#000",
									margin: 10,
									borderRadius: 20,
									minHeight: sw / (this.props.options.length + 1),
									justifyContent: "center",
									shadowColor: "#000",
									shadowOffset: {
										width: 0,
										height: 2,
									},
									shadowOpacity: 0.25,
									shadowRadius: 7.84,
									elevation: 12,
								}}
								onPress={() =>
									this.nextPage({
										question: this.props.question,
										question_number: this.props.question_number,
										answer: d.option,
										mark: d.mark,
									})
								}>
								{typeof d.img !== "undefined" && d.img !== "" ? (
									<Image
										source={d.img}
										style={{
											width: 100,
											height: d.imgH,
											// height: "50%",
											resizeMode: "contain",
										}}
									/>
								) : null}
								<View
									style={{
										justifyContent: "center",
										alignItems: "center",
									}}>
									<p
										style={{
											color: "#FFF",
											fontWeight: "500",
											textAlign: "center",
											padding: 15,
											maring: 5,
										}}>
										{d.option}
									</p>
								</View>
							</TouchableOpacity>
						))}
					</View>
				</View>
			</Fade>
		);
	};

	render() {
		let ar = 937 / 649;

		let sh = this.props.screenHeight;
		let sw = this.props.screenWidth;

		return (
			<View
				style={{
					flex: 1,
					width: sw,
					height: sh,
				}}>
				{this.options(sh, sw)}
			</View>
		);
	}
}
