import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import Lottie from "react-lottie";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
import logo from "./logo.png";
import logo_tetrice from "./logo_tetrice.png";
import nomobile from "./nomobile.png";
import tick from "./tick_red.png";
import traq_logo from "./traq_app_logo.svg";
import bg2 from "./bg2.svg";
import "./index.css";
import { api_deviceRegister } from "../Api";
import * as animationData from "./lf30_editor_sdru9bf3.json";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			passcode: "",
			uniqueID: "",
			uniqueIDValid: false,
			screenWidth: screenWidth,
			screenHeight: screenHeight,

			otpPinSent: false,
		};
	}

	registerDeviceBtn = async () => {
		this.setState({
			loading: true,
			errorLogin: false,
		});

		await localStorage.setItem("uniqueID", this.state.uniqueID);

		this.loginFuncWithOTP();
	};

	loginFuncWithOTP = async () => {
		let url = api_deviceRegister;
		let passcode = this.state.passcode;
		let uniqueID = this.state.uniqueID;

		var raw = JSON.stringify({
			passcode: passcode,
			uniqueID: uniqueID,
		});

		var requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: raw,
		};

		// console.log(url, requestOptions);
		// return;

		await fetch(url, requestOptions)
			.then((response) => {
				// console.log("response.status", response.status);
				console.log("response =====", response);
				if (response.status == 200) {
					response
						.json()
						.then(async (responseData) => {
							await console.log(
								"=============================",
								responseData,
							);
							let user_data = await sessionStorage.setItem(
								"user_data",
								JSON.stringify(responseData),
							);
							let accessToken = await sessionStorage.setItem(
								"accessToken",
								responseData.accessToken,
							);

							let sessionExpiry = await sessionStorage.setItem(
								"sessionExpiry",
								responseData.sessionExpiry,
							);

							let deviceInfo = await sessionStorage.setItem(
								"deviceInfo",
								JSON.stringify(responseData.deviceInfo),
							);

							this.props.navigate("/");
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
								passcode: "",
								uniqueID: "",
								emailValid: false,
								otpPinSent: false,
							});
						});
				} else {
					// alert("User has not been found");
					this.setState({
						errorLogin: true,
						loading: false,
						passcode: "",
						uniqueID: "",
						emailValid: false,
						otpPinSent: false,

						uniqueIDValid: false,
						passcodeValid: false,
					});
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					errorLogin: true,
					loading: false,
					passcode: "",
					uniqueID: "",
					emailValid: false,
					otpPinSent: false,
					uniqueIDValid: false,
					passcodeValid: false,
				});
			});
	};

	componentDidMount() {
		let accessToken = sessionStorage.getItem("accessToken");
		let uniqueID = sessionStorage.getItem("uniqueID");
		console.log("Approved uniqueID", accessToken);
		if (accessToken !== null && accessToken !== "") {
			this.props.navigate("/");
		} else {
			sessionStorage.clear();
			this.setState({
				loading: false,
			});
		}
		console.log("NAVIGATE ==== ");
		window.addEventListener("resize", this.resize.bind(this));
		this.resize();

		//
	}

	componentDidUpdate() {}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		let contain =
			this.state.screenWidth * 0.9 > this.state.screenHeight * 0.9
				? this.state.screenHeight * 0.89
				: this.state.screenWidth * 0.9;

		let maxContain = contain > 520 ? 520 : contain;

		let ar = 636 / 545;

		if (this.state.screenWidth < 700) {
			return (
				<div
					style={{
						backgroundColor: "#131119",
						flex: 1,
					}}>
					<View
						style={{
							flex: 1,
							alignItems: "center",
							// marginTop:
							height: this.state.screenHeight,
							padding: 10,
							justifyContent: "center",
						}}>
						<View
							style={{
								width: "100%",
								maxWidth: 400,
								alignItems: "center",
								padding: 10,
								borderRadius: 15,
								backgroundColor: "#121419",

								shadowColor: "#000",
								shadowOffset: {
									width: 0,
									height: 8,
								},
								shadowOpacity: 0.44,
								shadowRadius: 10.32,

								elevation: 16,
							}}>
							<Image
								source={logo}
								style={{
									width: 180,
									height: 100,
									resizeMode: "contain",
								}}
							/>

							<View style={{}}>
								<Image
									source={nomobile}
									style={{
										width: 80,
										height: 80,
										resizeMode: "contain",
										marginTop: 15,
										marginBottom: 15,
									}}
								/>
							</View>

							<View
								style={{
									width: 300,
									marginTop: 10,
									marginBottom: 50,
									opacity: 0.7,
								}}>
								<Text
									style={{
										color: "#FFF",
										marginBottom: 15,
										textAlign: "center",
									}}>
									TRAQ Survey is not avaliable for smaller mobile
									devices.
								</Text>
								<Text
									style={{
										color: "#FFF",
										marginBottom: 15,
										textAlign: "center",
									}}>
									Try using a tablet or desktop device.
								</Text>
							</View>
						</View>
					</View>
				</div>
			);
		} else
			return (
				<div
					style={{
						flex: 1,
						width: this.state.screenWidth,
						height: this.state.screenHeight,
						backgroundColor: "#1F2229",
						overflow: "hidden",
					}}>
					<View
						style={{
							width: this.state.screenWidth,
							height: this.state.screenHeight,
							alignItems: "center",
							justifyContent: "center",
						}}>
						<View
							style={{
								width: this.state.screenWidth,
								height: this.state.screenHeight,
								right: 0,
								bottom: 0,
								position: "absolute",
							}}>
							<Image
								source={bg2}
								style={{
									width: maxContain * ar,
									height: maxContain,
									resizeMode: "contain",
									position: "absolute",
									left: -10,
									bottom: -10,
								}}
							/>
						</View>

						<View
							style={{
								position: "absolute",
								bottom: "10%",
								left: "1%",
							}}>
							<Lottie
								options={defaultOptions}
								height={contain > 600 ? 600 : contain}
								width={contain > 600 ? 600 : contain}
							/>
						</View>

						<View
							style={{
								flex: 1,
								flexDirection: "row",
								width: this.state.screenWidth,
								height: this.state.screenHeight,
							}}>
							<View
								style={{
									flex: 1,
								}}>
								<View
									style={{
										position: "absolute",
										top: "10%",
										left: "2%",
									}}>
									<Image
										source={traq_logo}
										style={{
											width: 190,
											height: 70,
											resizeMode: "contain",
										}}
									/>
								</View>
							</View>

							<View
								style={{
									flex: 1,
									alignItems: "center",
									// justifyContent: "center",
									justifyContent: "space-evenly",
								}}>
								<View
									style={{
										width: 307,
										height: "auto",
										// top: 80,
										// marginTop: "10%",
										alignItems: "center",
										padding: 16,
										borderRadius: 25,
										backgroundColor: "#121419",
										shadowColor: "#000",
										shadowOffset: {
											width: 0,
											height: 8,
										},
										shadowOpacity: 0.44,
										shadowRadius: 10.32,
										elevation: 16,
									}}>
									<Image
										source={require("./traq_survey.png")}
										style={{
											width: 105,
											height: 50,
											resizeMode: "contain",
											margin: 5,
										}}
									/>

									<View
										style={{
											flex: 1,
											width: "100%",
											alignItems: "center",
											// justifyContent: 'center',
										}}>
										<Fade>
											<div
												style={{
													height: "100%",
													width: "100%",
													alignItems: "center",
													justifyContent: "center",
												}}>
												<View
													style={{
														width: "100%",
														padding: 10,
														marginRight: "auto",
														marginLeft: "auto",
														justifyContent: "center",
														alignItems: "center",
														maxWidth: 350,
														marginTop: 15,
														borderWidth: 0.3,
														borderColor: "#1F2229",
														backgroundColor: "#1F2229",
														borderRadius: 12,
														flexDirection: "row",
													}}>
													{this.state.uniqueIDValid ? (
														<Image
															source={tick}
															style={{
																width: 20,
																height: 20,
																resizeMode: "contain",

																position: "absolute",
																left: 10,
															}}
														/>
													) : null}
													<TextInput
														style={{
															width: "100%",
															fontSize: 16,
															textAlign: "center",
															color: "#FBFBFC",
															fontWeight: "200",
															fontFamily: "Avenir",
															letterSpacing: 0.26,
														}}
														placeholderTextColor={"#797979"}
														autoCapitalize='none'
														clearButtonMode='while-editing'
														autoCorrect={false}
														autoFocus={true}
														placeholder={`Device ID`}
														value={this.state.uniqueID}
														onChangeText={(text) => {
															let uniqueIDValid =
																text.length > 4;
															this.setState({
																uniqueID: text,
																uniqueIDValid: uniqueIDValid,
															});
														}}
													/>
												</View>
											</div>
										</Fade>

										<Fade>
											<div
												style={{
													height: "100%",
													width: "100%",
													alignItems: "center",
													justifyContent: "center",
												}}>
												<View
													style={{
														width: "100%",
														padding: 10,
														marginRight: "auto",
														marginLeft: "auto",
														justifyContent: "center",
														alignItems: "center",
														maxWidth: 350,
														marginTop: 15,
														borderWidth: 0.3,
														borderColor: "#1F2229",
														backgroundColor: "#1F2229",
														borderRadius: 12,
														flexDirection: "row",
													}}>
													{this.state.passcodeValid ? (
														<Image
															source={tick}
															style={{
																width: 20,
																height: 20,
																resizeMode: "contain",
																position: "absolute",
																left: 10,
															}}
														/>
													) : null}
													<TextInput
														style={{
															width: "100%",
															fontSize: 16,
															textAlign: "center",
															color: "#FBFBFC",
															fontWeight: "200",
															fontFamily: "Avenir",
															letterSpacing: 0.26,
														}}
														placeholderTextColor={"#797979"}
														autoCapitalize='none'
														clearButtonMode='while-editing'
														autoCompleteType='password'
														secureTextEntry={true}
														textContentType='password'
														autoCorrect={false}
														placeholder={`Password`}
														value={this.state.passcode}
														// defaultValue={
														// 	this.state.passcode
														// }
														onChangeText={(text) => {
															let passcodeValid =
																text.length > 4;
															this.setState({
																passcode: text,
																passcodeValid: passcodeValid,
															});
														}}
														onSubmitEditing={() =>
															this.loginButton()
														}
													/>
												</View>
											</div>
										</Fade>

										<Fade>
											<div
												style={{
													height: "100%",
													width: "100%",
													alignItems: "center",
													justifyContent: "center",
													marginTop: 15,
												}}>
												<TouchableOpacity
													disabled={this.state.loading}
													onPress={() => this.registerDeviceBtn()}>
													<View
														style={{
															backgroundColor: this.state
																.otpPinSent
																? "#0676ED"
																: "#0068FF",
															flex: 1,
															padding: 13,
															justifyContent: "center",
															alignItems: "center",
															borderRadius: 15,
														}}>
														{this.state.loading ? (
															<ActivityIndicator color='#FFF' />
														) : (
															<Text
																style={{
																	color: "#FFF",
																}}>
																Register device
															</Text>
														)}
													</View>
												</TouchableOpacity>
											</div>
										</Fade>
									</View>
								</View>
								<View
									style={{
										paddingLeft: 30,
									}}>
									<Text
										style={{
											fontSize: 26,
											color: "#FFF",
										}}>
										<Font family='Poppins' weight={400}>
											Professional Staff &
										</Font>
									</Text>
									<Text
										style={{
											fontSize: 26,
											color: "#FFF",
										}}>
										<Font family='Poppins' weight={400}>
											Retail management tools
										</Font>
									</Text>
								</View>
							</View>
						</View>
					</View>
					<View
						style={{
							width: this.state.screenWidth,
							position: "absolute",
							bottom: 30,
							left: 0,
							flexDirection: "row",
							justifyContent: "space-between",
							paddingRight: 30,
							paddingLeft: 30,
						}}>
						<Text style={{ width: 110, fontSize: 16, color: "#121419" }}>
							<Font family='Poppins' weight={800}>
								tetrice
							</Font>
						</Text>
						<View
							style={{
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "center",
							}}>
							<Image
								source={require("./google_play.png")}
								style={{
									width: 130,
									height: 32,
									resizeMode: "contain",
									marginRight: 10,
								}}
							/>

							<Image
								source={require("./app_store.png")}
								style={{
									width: 130,
									height: 32,
									resizeMode: "contain",
								}}
							/>
						</View>
						<Text
							style={{
								width: 110,
								fontSize: 13,
								color: "#FFF",
							}}>
							<Font family='Poppins' weight={300}>
								info@traq.app
							</Font>
						</Text>
					</View>
				</div>
			);
	}
}

export const withNavigation = (Component) => {
	return (props) => <Component {...props} navigate={useNavigate()} />;
};

export default withNavigation(index);
