import React, { Component } from "react";
import {
	View,
	Modal,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
	ActivityIndicator,
	ScrollView,
} from "react-native";
import { APP_COLOURS } from "../APP_VARS";
import moment from "moment";
import Font from "react-font";

export default class DateOfBirth extends Component {
	constructor(props) {
		super(props);
		this.state = {
			day: "",
			dob: "",
			month: "",
			year: "",
		};
		this._timeout = 500;
	}
	render() {
		return (
			<View>
				<View
					style={{
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "row",
						borderWidth: this.state.dob !== "" ? 0 : 0.3,
						backgroundColor: "#FFF",
						borderColor: "#000",
						borderRadius: 15,
						// paddingLeft: 40,
						marginBottom: 5,
						padding: 5,
					}}>
					<TextInput
						ref={(ref) => {
							this.day = ref;
						}}
						style={{
							padding: 10,
							width: 60,
							fontSize: 16,
							color: "#000",
							fontWeight: this.state.day !== "" ? "500" : "200",
							// fontFamily: "Avenir",
							letterSpacing: 0.26,
							textAlign: "center",
						}}
						placeholderTextColor={"#797979"}
						autoCapitalize='sentences'
						clearButtonMode='while-editing'
						autoCorrect={false}
						maxLength={2}
						keyboardType='number-pad'
						placeholder={"DD"}
						onFocus={() => {
							this.day.clear();
							this.month.clear();
							this.year.clear();
							this.setState({
								day: "",
								dob: "",
								month: "",
								year: "",
							});
						}}
						value={this.state.day}
						onChange={(e) => {
							e.preventDefault();
							let t = e.target.value;
							this.setState({
								day: t,
								dob: "",
							});
							if (t.length == 2) {
								this.month.clear();
								this.month.focus();
							}
						}}
					/>

					<Text>/</Text>

					<TextInput
						ref={(ref) => {
							this.month = ref;
						}}
						style={{
							padding: 5,
							width: 60,
							fontSize: 16,
							color: "#000",
							fontWeight: this.state.month !== "" ? "500" : "200",
							// fontFamily: "Avenir",
							letterSpacing: 0.26,
							textAlign: "center",
						}}
						placeholderTextColor={"#797979"}
						autoCapitalize='sentences'
						clearButtonMode='while-editing'
						autoCorrect={false}
						maxLength={2}
						keyboardType='number-pad'
						onFocus={() => {
							this.month.clear();
							this.year.clear();
							if (this.state.day === "") {
								this.day.focus();
							}
							this.setState({
								month: "",
								year: "",
								dob: "",
							});
						}}
						placeholder={"MM"}
						value={this.state.month}
						onChange={(e) => {
							e.preventDefault();
							let t = e.target.value;
							if (parseInt(t) < 13) {
								this.setState({
									month: t,
									dob: "",
								});
								if (t.length == 2) {
									this.year.clear();
									this.year.focus();
								}
							}
						}}
					/>

					<Text>/</Text>

					<TextInput
						ref={(ref) => {
							this.year = ref;
						}}
						style={{
							padding: 5,
							width: 60,
							fontSize: 16,
							color: "#000",
							fontWeight: this.state.year !== "" ? "500" : "200",
							letterSpacing: 0.26,
							textAlign: "center",
						}}
						placeholderTextColor={"#797979"}
						autoCapitalize='sentences'
						clearButtonMode='while-editing'
						autoCorrect={false}
						maxLength={4}
						keyboardType='number-pad'
						placeholder={"YYYY"}
						onFocus={() => {
							this.year.clear();

							if (this.state.month === "") {
								this.month.focus();
							}
							this.setState({
								year: "",
								dob: "",
							});
						}}
						value={this.state.year}
						onChange={(e) => {
							e.preventDefault();
							let t = e.target.value;
							let dob = "";
							let dateStr = `${this.state.day}/${this.state.month}/${t}`;

							let date_ = moment(dateStr, "DD/MM/YYYY");
							console.log(date_);
							let thisYear = moment().format("YYYY");
							if (parseInt(t) > 0 && parseInt(t) < thisYear) {
								dob =
									moment(date_).isValid() && t.length === 4
										? date_
										: "";
								this.setState({
									year: t,
									dob: dob,
								});
								clearTimeout(this._timeout);
								this._timeout = setTimeout(() => {
									let dm = moment(date_).format("DD/MM/YYYY");
									this.props.setParentState(dm);
								}, 500);
							} else {
								this.setState({
									year: "",
									dob: "",
								});
							}
						}}
					/>

					{/* {this.state.dateValid ? (
                                 <Image
                                    source={tick}
                                    style={{
                                       width: 16,
                                       height: 16,
                                       resizeMode: "contain",
                                       position: "absolute",
                                       left: 15,
                                       top: 17,
                                    }}
                                 />
                              ) : null} */}
				</View>
				<View
					style={{
						flexDirection: "row",
						marginBottom: 5,
						alignItems: "center",
						justifyContent: "center",
						width: "100%",
					}}>
					<Text
						style={{
							color: "#000",
							fontSize: 12,
						}}>
						<Font weight={500} family='Montserrat'>
							{this.state.dob !== ""
								? `${moment().diff(this.state.dob, "years")} years old`
								: ""}
						</Font>
					</Text>
				</View>
			</View>
		);
	}
}
