import React, { Component } from "react";
import { View, Text, Dimensions, Image, TouchableOpacity } from "react-native";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";
// import moment from "moment";
import { APP_COLOURS } from "./APP_VARS";
import Fade from "react-reveal/Fade";
import Welcome from "./Pages/index";
import DeviceAuth from "./Unauthed/Login";
import main from "./assets/images/main.png";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

if (typeof window !== "undefined") {
	require("smoothscroll-polyfill").polyfill();
}

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			screenWidth: screenWidth,
			screenHeight: screenHeight,
			accessToken: "",
		};
	}

	async componentDidMount() {
		// let user_data = await sessionStorage.getItem("user_data");
		// let accessToken = await sessionStorage.getItem("accessToken");

		// if (user_data !== null && accessToken !== null) {
		// 	this.setState({
		// 		accessToken: accessToken,
		// 		user_data: JSON.parse(user_data),
		// 	});
		// } else {
		// }

		await window.addEventListener("resize", this.resize.bind(this));
		await this.resize();
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
		} else {
		}
	}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	render() {
		const isProduction = process.env.NODE_ENV === "production";
		return (
			<Router>
				<Routes>
					<Route path='/' element={<Welcome {...this.state} />} />
					<Route path='/login' element={<DeviceAuth {...this.state} />} />

					{/* <Route
							path='/home'
							element={
								<Home
									{...this.state}
									screenWidth={screenWidth}
									screenHeight={screenHeight}
									logOut={async () => {
										await sessionStorage.clear();
										await sessionStorage.clear();
										await this.setState({
											accessToken: "",
											permission: "",
										});
										window.location.href = "./";
										// return <Navigate to='/' replace={true} />;
									}}
								/>
							}
						/> */}

					<Route path='*' element={<Navigate to='/login' />} />
				</Routes>
			</Router>
		);
	}
}

export default App;
