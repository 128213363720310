import React, { Component } from "react";
import { View, Text, Dimensions, Image, TouchableOpacity } from "react-native";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Font from "react-font";
import Fade from "react-reveal/Fade";
import "./blur.css";
import main from "../assets/images/main.png";
import main2 from "../assets/images/conbg2.png";
import Page1 from "./Page1";
import Page2 from "./Page2";
import { APP_COLOURS } from "../APP_VARS";
import ResultsPage from "./ResultsPage";
import UserDetails from "./UserDetails";
import con_logo from "../assets/images/con_logo.png";
import lets_begin from "../assets/images/lets_begin.png";
// import img1 from "../assets/images/img1.png";
// import img2 from "../assets/images/img2.png";
// import img3 from "../assets/images/img3.png";
// import AllProducts from "./AllProducts";

import { api_submitSurvey, api_heartBeat } from "../Api";

import str from "../assets/images/straight.png";
import wav from "../assets/images/wavey.png";
import cur from "../assets/images/curly.png";
import coi from "../assets/images/coil.png";

function miliseconds(hrs, min, sec) {
	return (hrs * 60 * 60 + min * 60 + sec) * 1000;
}

class Welcome extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			showButton: false,
			page: 0,
			letsBegin: false,
			startBlur: false,
			userDataPage: false,
			resultsPage: true,
			accessToken: "",
			viewProducts: false,
			questions: [
				{
					id: "1",
					question_number: 1,
					question: "Select one of the following:",
					options: [
						{
							num: 1,
							option: "I wear my natural hair",
							mark: "natural",
							// img: img1,

							imgH: 50,
						},
						{
							num: 2,
							option: "My hair is relaxed",
							mark: "relaxed",
							// img: img2,
							imgH: 50,
						},
						{
							num: 3,
							option: "I wear a protective hair style",
							mark: "protective",
							// img: img3,
							imgH: 50,
						},
					],
				},
				{
					id: "2",
					question_number: 2,
					question: "Tell us your hair type:",
					options: [
						{
							num: 1,
							option: "A",
							mark: "TypeA",
							img: str,
							imgH: 250,
						},
						{
							num: 2,
							option: "B",
							mark: "TypeB",
							img: wav,
							imgH: 250,
						},
						{
							num: 3,
							option: "C",
							mark: "TypeC",
							img: cur,
							imgH: 250,
						},
						{
							num: 3,
							option: "D",
							mark: "TypeD",
							img: coi,
							imgH: 250,
						},
					],
				},
				{
					id: "3",
					question_number: 3,
					question: "My hair needs?",
					options: [
						{
							num: 1,
							option: "Moisture",
							mark: "Moisture",
							// img: img1,
							imgH: 50,
						},
						{
							num: 2,
							option: "Strengthening",
							mark: "Strengthening",
							// img: img2,
							imgH: 50,
						},
						{
							num: 3,
							option: "Shine",
							mark: "Shine",
							// img: img3,
							imgH: 50,
						},
					],
				},
				// {
				// 	id: "4",
				// 	question_number: 4,
				// 	question: "Curl option - Select your curl",
				// 	options: [
				// 		{
				// 			num: 1,
				// 			option: "Classic S Curl",
				// 			mark: "3A",
				// 			img: img1,
				// 			imgH: 30,
				// 		},
				// 		{
				// 			num: 2,
				// 			option: "Spiral Curl",
				// 			mark: "3B",
				// 			img: img2,
				// 			imgH: 30,
				// 		},
				// 		{
				// 			num: 3,
				// 			option: "Corkskrew Curl",
				// 			mark: "3C",
				// 			img: img3,
				// 			imgH: 30,
				// 		},
				// 	],
				// },
				// {
				// 	id: "3",
				// 	question_number: 5,
				// 	question: "Coil option - Select your coil",
				// 	options: [
				// 		{
				// 			num: 1,
				// 			option: "Slight coil",
				// 			mark: "4A",
				// 			img: img1,
				// 			imgH: 30,
				// 		},
				// 		{
				// 			num: 2,
				// 			option: "Kinky",
				// 			mark: "4B",
				// 			img: img2,
				// 			imgH: 30,
				// 		},
				// 		{
				// 			num: 3,
				// 			option: "Super kinky",
				// 			mark: "4C",
				// 			img: img3,
				// 			imgH: 30,
				// 		},
				// 	],
				// },
				// {
				// 	id: "6",
				// 	question_number: 6,
				// 	question: "Do you have braids",
				// 	options: [
				// 		{
				// 			num: 1,
				// 			option: "Yes",
				// 			mark: "5",
				// 			img: img1,
				// 			imgH: 30,
				// 		},
				// 		{
				// 			num: 2,
				// 			option: "No",
				// 			mark: "",
				// 			img: img2,
				// 			imgH: 30,
				// 		},
				// 	],
				// },
			],
			prescription: {
				a: "",
				b: "",
				c: "",
			},

			userAnswers: [],
			userDetails: {
				first_name: "",
				last_name: "",
				cell: "",
				email: "",
				age: "",
				gender: "",
				ethnicity: "",
				location: "",
				address: "",
				date_added: "",
			},
		};

		this._timeout = 5000;
	}

	async componentDidMount() {
		await this.validateSession();
		setTimeout(() => {
			this.setState({
				startBlur: true,
			});
		}, 1000);
		setTimeout(() => {
			this.setState({
				letsBegin: true,
			});
			this.heartBeat();
		}, 2000);
	}

	validateSession = async () => {
		let accessToken = await sessionStorage.getItem("accessToken");
		let deviceInfo = await sessionStorage.getItem("deviceInfo");
		let uniqueID = await sessionStorage.getItem("uniqueID");
		let sessionExpiry = await sessionStorage.getItem("sessionExpiry");

		var now = moment(); //todays date
		var end = moment().add(30, "minutes"); // another date
		var duration = moment.duration(now.diff(end));

		const dur = -1 * duration._milliseconds;

		console.log("accessToken", accessToken);
		if (accessToken !== null && accessToken !== "") {
			await this.setState({
				accessToken,
				uniqueID,
				deviceInfo: JSON.parse(deviceInfo),
			});
			setTimeout(() => {
				this.setState({
					loading: false,
					authenticating: false,
				});
			}, 1000);

			setTimeout(() => {
				sessionStorage.clear();
				// this.props.navigate("/");
				window.location.href = "./login";
			}, dur);
		} else {
			// this.props.navigate("/");
			sessionStorage.clear();
			window.location.href = "./login";
		}
	};

	render() {
		let ar = 937 / 649;

		let sh = this.props.screenHeight;
		let sw = this.props.screenWidth;

		let sd = sh > sw ? sw : sh;
		return (
			<View
				style={{
					flex: 1,
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "",
					width: sw,
					height: sh,
					overflow: "hidden",
				}}>
				<Fade collapse when={this.state.page < 1}>
					<div
						className={this.state.startBlur ? "blurloop" : "noblur"}
						style={{
							flex: 1,
							justifyContent: "center",
							alignItems: "center",
						}}>
						<Image
							source={main}
							style={{
								width: sw,
								height: sh,
								resizeMode: "cover",
							}}
						/>
					</div>
				</Fade>

				<Fade
					collapse
					when={
						this.state.page >= 1 &&
						this.state.page <= this.state.questions.length
					}>
					<div
						// className='blur'
						style={{
							flex: 1,
							justifyContent: "center",
							alignItems: "center",
							width: sw,
							height: sh,
							backgroundColor: "#F3F3F3",
						}}>
						{/* <Image
							source={main2}
							style={{
								width: sw,
								height: sh,
								resizeMode: "cover",
							}}
						/> */}
						{/* <View
							style={{
								position: "absolute",
								top: 0,
								left: 0,
								zIndex: 99,
							}}>
							<Image
								source={require("./../assets/images/leftTop.png")}
								style={{
									width: sd * 0.2,
									height: sd * 0.2,
									resizeMode: "contain",
								}}
							/>
						</View> */}
						<View
							style={{
								position: "absolute",
								bottom: 0,
								left: 0,
								zIndex: 99,
							}}>
							<Image
								source={require("./../assets/images/pathBottomLeft.png")}
								style={{
									width: sd * 0.2 * (849 / 708),
									height: sd * 0.2,
									resizeMode: "contain",
								}}
							/>
						</View>
						<View
							style={{
								position: "absolute",
								top: 0,
								right: 0,
								zIndex: 99,
							}}>
							<Image
								source={require("./../assets/images/rightTop.png")}
								style={{
									width: sd * 0.2 * (756.13 / 395.21),
									height: sd * 0.2,
									resizeMode: "contain",
								}}
							/>
						</View>
						<View
							style={{
								position: "absolute",
								bottom: 0,
								right: 0,
								zIndex: 99,
							}}>
							<Image
								source={require("./../assets/images/rightBottom.png")}
								style={{
									width: sd * 0.2 * (461 / 450),
									height: sd * 0.2,
									resizeMode: "contain",
								}}
							/>
						</View>
					</div>
				</Fade>

				{this.state.page > this.state.questions.length ? (
					<Fade>
						<div
							className='blur'
							style={{
								flex: 1,
								justifyContent: "center",
								alignItems: "center",
								position: "absolute",
								zIndex: 99,
								top: 0,
								left: 0,
							}}>
							{/* <Image
								source={main}
								style={{
									width: sw,
									height: sh,
									resizeMode: "cover",
								}}
							/> */}
						</div>
					</Fade>
				) : (
					<View />
				)}

				{this.state.letsBegin && this.state.page === 0 ? (
					<View
						style={[
							{
								// flex: 1,
								// justifyContent: "center",
								// alignItems: "center",
								zIndex: 90,
								position: "absolute",
								right: 0,
								width: sh * (470 / 886),
								height: sh,
							},
						]}>
						<Fade>
							<TouchableOpacity
								style={{}}
								onPress={() => {
									this.startInteraction();
								}}>
								<Image
									source={lets_begin}
									style={{
										width: sh * (470 / 886),
										height: sh,
										resizeMode: "cover",
										shadowColor: "#000",
										shadowOffset: {
											width: 0,
											height: 2,
										},
										shadowOpacity: 0.25,
										shadowRadius: 7.84,
										elevation: 12,
									}}
								/>
							</TouchableOpacity>
						</Fade>
					</View>
				) : (
					<View />
				)}

				{this.state.letsBegin && this.state.page === 0 ? (
					<View
						style={[
							{
								// flex: 1,
								justifyContent: "center",
								zIndex: 90,
								position: "absolute",
								left: -20,
								width: 250,
								// height: sh,
								bottom: "20%",
							},
						]}>
						<Fade>
							<TouchableOpacity
								onPress={() => {
									this.setState({
										viewProducts: true,
									});
								}}>
								<View
									style={{
										backgroundColor: APP_COLOURS.YELLOW,
										width: "100%",
										height: 45,
										borderRadius: 25,
										justifyContent: "center",
										alignItems: "center",
										shadowColor: "#000",
										shadowOffset: {
											width: 0,
											height: 2,
										},
										shadowOpacity: 0.25,
										shadowRadius: 7.84,
										elevation: 12,
									}}>
									<Text
										style={{
											alignItems: "center",
											justifyContent: "center",
											color: "#000",
											fontSize: 17,
										}}>
										<Font weight={700} family='Montserrat'>
											View products
										</Font>
									</Text>
								</View>
							</TouchableOpacity>
						</Fade>
					</View>
				) : (
					<View />
				)}

				{/* {this.state.viewProducts ? (
					<AllProducts {...this.props} />
				) : (
					<View />
				)} */}

				{this.state.page > 0 &&
				this.state.page <= this.state.questions.length ? (
					<View
						style={[
							{
								height: sh,
								width: sw,
								// justifyContent: "center",
								alignItems: "center",
								zIndex: 90,
								position: "absolute",
							},
						]}>
						{this.state.page <= this.state.questions.length ? (
							<Image
								source={con_logo}
								style={{
									width: sw * 0.2,
									height: 40,
									resizeMode: "contain",
									position: "absolute",
									zIndex: 999,
									top: 20,
								}}
							/>
						) : (
							<View />
						)}
						{this.state.questions.map((d, i) => {
							return (
								<View key={i} style={{}}>
									{this.state.page === d.question_number ? (
										<Page1
											{...this.props}
											page={d.question_number}
											question_number={d.question_number}
											question={d.question}
											options={d.options}
											nextPage={async (data) =>
												this.addAnswer(data, d)
											}
										/>
									) : (
										<View />
									)}
								</View>
							);
						})}
					</View>
				) : (
					<View />
				)}

				{this.state.page <= this.state.questions.length ? (
					<View
						style={{
							width: sw,
							height: 50,
							// marginBottom: 12,
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "row",
							// borderTopWidth: 0.5,
							opacity: 0.8,
							position: "absolute",
							zIndex: 999,
							bottom: 0,
							// paddingLeft: 10,
						}}>
						<Text
							style={{
								fontSize: 11,
								color: "#141D31",
							}}>
							© {moment().format("YYYY")} TRAQ Powered by{" "}
						</Text>
						<Text
							style={{
								fontSize: 12,
								paddingTop: 1,
								color: "#141D31",
							}}>
							<a
								style={{
									textDecoration: "none",
									marginimgH: 30,
									color: "#141D31",
								}}
								target='_blank'
								rel='noreferrer'
								href='https://www.tetrice.co.za'>
								<Font weight={800} family='Poppins' color={"#141D31"}>
									tetrice
								</Font>
							</a>
						</Text>
					</View>
				) : (
					<View />
				)}

				{/* // USER DATA SKIPPED  */}
				{this.state.page > this.state.questions.length &&
				this.state.userDataPage ? (
					<UserDetails
						{...this.props}
						{...this.state}
						nextPage={() =>
							this.setState({
								resultsPage: true,
							})
						}
					/>
				) : (
					<View />
				)}

				{this.state.page > this.state.questions.length &&
				this.state.resultsPage ? (
					<ResultsPage {...this.props} {...this.state} />
				) : (
					<View />
				)}
			</View>
		);
	}

	addAnswer = async (data, d) => {
		let userAnswers = await [...this.state.userAnswers];
		await userAnswers.push(data);
		await this.setState({
			page: d.question_number + 1,
			userAnswers: userAnswers,
		});
		await console.log("userAnswers", userAnswers);

		//
		// IF SURVEY HAS BEEN COMPLETE
		//

		if (d.question_number + 1 > this.state.questions.length) {
			clearTimeout(this._timeout);
			this.submitResults(true, userAnswers);
		} else {
			await this.clearandStartInactivityTimer();
		}
	};

	startInteraction = async () => {
		let now = await moment().toISOString();
		await this.setState({
			page: 1,
			startTime: now,
		});
		this.clearandStartInactivityTimer();
	};

	clearandStartInactivityTimer = () => {
		clearTimeout(this._timeout);
		this._timeout = setTimeout(() => {
			this.submitResults(false, this.state.userAnswers);
		}, 5000);
	};

	submitResults = async (completed, userAnswers) => {
		clearTimeout(this._timeout);

		let startTime = this.state.startTime;
		let endTime = moment().toISOString();

		let obj = {
			deviceInfo: this.state.deviceInfo,
			userAnswers: userAnswers,
			completedSurvey: completed,
			qrscanned: false,
			customerLink: false,
			endTime: endTime,
			startTime: startTime,
		};

		this.setState({
			submittingSurvey: true,
			errorSubmitting: false,
		});

		var requestOptions = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.state.accessToken}`,
			},
			redirect: "follow",
			body: JSON.stringify(obj),
		};
		// console.log(api_submitSurvey, requestOptions);
		// return;
		await fetch(api_submitSurvey, requestOptions)
			.then(async (response) => {
				console.log("response =====", response);
				if (response.status === 200) {
					// window.location.href = "./";
					this._timeout = setTimeout(() => {
						window.location.href = "./";
					}, 60000);
				} else {
					// alert("Error submitting");
					// this.setState({
					// 	submittingSurvey: false,
					// 	errorSubmitting: true,
					// });
					this.logOut();
				}
			})
			.catch((error) => {
				alert("Error submitting");
				// this.setState({
				// 	submittingSurvey: false,
				// 	errorSubmitting: true,
				// });
				window.location.href = "./";
			});
	};

	heartBeat = async () => {
		let url = api_heartBeat;
		let now = moment().toISOString();

		let obj = {
			now: now,
		};

		var requestOptions = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.state.accessToken}`,
			},
			redirect: "follow",
			body: JSON.stringify(obj),
		};
		// console.log(url, requestOptions);
		// return;
		await fetch(url, requestOptions)
			.then(async (response) => {
				console.log("response =====", response);
				if (response.status === 200) {
				} else {
					this.logOut();
				}
			})
			.catch((error) => {
				alert("Error updating");
				window.location.href = "./";
			});
	};

	logOut = async () => {
		await sessionStorage.clear();
		await localStorage.clear();
		window.location.href = "./";
	};
}

export const withNavigation = (Component) => {
	return (props) => <Component {...props} navigate={useNavigate()} />;
};

export default withNavigation(Welcome);
